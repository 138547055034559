import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";
import { z } from "zod";

import { UnstyledLink } from "@dokworks/ui";

import { version } from "@/../package.json";
import { fallback } from "@/routes/login/-shared";
import { Logo } from "@/components/logo";

export const Route = createFileRoute("/login")({
  validateSearch: z.object({
    redirect: z.string().optional().catch(""),
  }),
  beforeLoad: ({ context, search }) => {
    if (context.auth.isAuthenticated) {
      throw redirect({ to: search.redirect || fallback });
    }
  },
  component: LoginLayout,
});

function LoginLayout() {
  return (
    <div className="flex flex-1 flex-col items-center justify-center px-6 py-2">
      <UnstyledLink href="/login">
        <Logo className="fixed left-6 top-4 z-10 mx-auto mb-4 h-16 drop-shadow-md" />
      </UnstyledLink>
      <div className="flex w-[480px] flex-1 flex-col justify-center">
        <div className="floating -mt-20 overflow-auto px-8 pb-8 pt-6">
          {/* <Logo className="z-10 mx-auto mb-4 h-20 drop-shadow-md" /> */}
          <Outlet />
          <p className="mx-auto mt-10 h-max text-center text-xs font-semibold leading-tight">
            <span className="text-fg-accent">v</span>
            {version}
          </p>
        </div>
      </div>
    </div>
  );
}
