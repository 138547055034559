import React from "react";

import ReactDOM from "react-dom/client";

// styling
import "@dokworks/styling";

import { I18nProvider } from "react-aria-components";

import { App } from "@/app";

(window.top ?? window).API_BASE_URL = __API_BASE_URL__;

const rootElement = document.getElementById("root")!;

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <I18nProvider locale="nl-NL">
        <App />
      </I18nProvider>
    </React.StrictMode>,
  );
}
